// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/var/jenkins_home/workspace/danone/danone-normal-frontend/node_modules/@umijs/preset-built-in/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@ant-design/pro-layout/es/PageLoading';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 't__plugin-layout__Layout' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/.umi-production/plugin-layout/Layout.tsx'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/user",
        "layout": false,
        "routes": [
          {
            "path": "/user",
            "routes": [
              {
                "passAuthVerify": true,
                "path": "/user/login",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__Login' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/user/Login'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "passAuthVerify": true,
                "path": "/user/dblogin",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__Login__dblogin' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/user/Login/dblogin'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "passAuthVerify": true,
                "path": "/user/login/acs",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__Login__acs' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/user/Login/acs'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "passAuthVerify": true,
                "path": "/user/outside/login",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__Login__outside' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/user/Login/outside'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "passAuthVerify": true,
        "path": "/welcome",
        "icon": "smile",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Welcome' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/Welcome'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/budget",
        "layout": true,
        "locale": false,
        "dynamic": true,
        "routes": [
          {
            "path": "/budget/hq",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__budget__HQ' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/budget/HQ'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/budget/budgetBaselineYtd",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__budget__BudgetBaselineYtd' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/budget/BudgetBaselineYtd'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/budget/budgetBaselineMtd",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__budget__BudgetBaselineMtd' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/budget/BudgetBaselineMtd'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/budget/budgetBaselineEc",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__budget__BudgetBaselineEc' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/budget/BudgetBaselineEc'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/budget/unitExpenseSetting",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__budget__UnitExpenseSetting' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/budget/UnitExpenseSetting'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/budget/pricePosSetting",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__budget__PricePosSetting' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/budget/PricePosSetting'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/budget/salesTargetCust",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__budget__SalesTargetCust' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/budget/SalesTargetCust'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/budget/salesRateSetting",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__budget__SalesRateSetting' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/budget/SalesRateSetting'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/budget/salesLimitSetting",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__budget__SalesLimitSetting' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/budget/SalesLimitSetting'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/anp",
        "layout": true,
        "locale": false,
        "dynamic": true,
        "routes": [
          {
            "path": "/anp/ioGroup",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__anp__AnpIoGroup' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/anp/AnpIoGroup'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/anp/io",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__anp__AnpIo' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/anp/AnpIo'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          }
        ]
      },
      {
        "path": "/project",
        "layout": true,
        "locale": false,
        "dynamic": true,
        "routes": [
          {
            "path": "/project/budgetproject",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__project__BudgetProject' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/project/BudgetProject'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/project/formbudgetproject",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__project__FormBudgetProject' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/project/FormBudgetProject'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/project/generalprojectapply",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__project__GeneralProjectApply' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/project/GeneralProjectApply'), loading: LoadingComponent}),
            "locale": false,
            "passAuthVerify": true,
            "exact": true
          },
          {
            "path": "/project/generalprojectapproval",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__project__GeneralProjectApproval' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/project/GeneralProjectApproval'), loading: LoadingComponent}),
            "locale": false,
            "passAuthVerify": true,
            "exact": true
          },
          {
            "path": "/project/budgetprojectdisplay",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__project__BudgetProjectDisplay' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/project/BudgetProjectDisplay'), loading: LoadingComponent}),
            "locale": false,
            "passAuthVerify": true,
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/sales",
        "layout": true,
        "locale": false,
        "dynamic": true,
        "routes": [
          {
            "path": "/sales/sa",
            "routes": [
              {
                "path": "/sales/sa/activity",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__sales__sa__Activity' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/sales/sa/Activity'), loading: LoadingComponent}),
                "locale": false,
                "exact": true
              },
              {
                "path": "/sales/sa/myactivity",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__sales__sa__MyActivity' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/sales/sa/MyActivity'), loading: LoadingComponent}),
                "locale": false,
                "name": "我的活动申请",
                "exact": true
              },
              {
                "path": "/sales/sa/GeneralActivityApproval",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__sales__sa__GeneralActivityApproval' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/sales/sa/GeneralActivityApproval'), loading: LoadingComponent}),
                "locale": false,
                "passAuthVerify": true,
                "exact": true
              },
              {
                "path": "/sales/sa/FreeGoodsApproval",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__sales__sa__FreeGoodsApproval' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/sales/sa/FreeGoodsApproval'), loading: LoadingComponent}),
                "locale": false,
                "passAuthVerify": true,
                "exact": true
              },
              {
                "path": "/sales/sa/PriceDiscountApproval",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__sales__sa__PriceDiscountApproval' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/sales/sa/PriceDiscountApproval'), loading: LoadingComponent}),
                "locale": false,
                "passAuthVerify": true,
                "exact": true
              },
              {
                "path": "/sales/sa/DealerActivityApproval",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__sales__sa__DealerActivityApproval' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/sales/sa/DealerActivityApproval'), loading: LoadingComponent}),
                "locale": false,
                "passAuthVerify": true,
                "exact": true
              }
            ]
          },
          {
            "path": "/sales/ss",
            "routes": [
              {
                "path": "/sales/ss/settle",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__sales__ss__Settle' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/sales/ss/Settle'), loading: LoadingComponent}),
                "locale": false,
                "exact": true
              },
              {
                "path": "/sales/ss/MySettle",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__sales__ss__MySettle' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/sales/ss/MySettle'), loading: LoadingComponent}),
                "locale": false,
                "name": "我的活动核销单",
                "exact": true
              },
              {
                "path": "/sales/ss/settle",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__sales__ss__Settle' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/sales/ss/Settle'), loading: LoadingComponent}),
                "locale": false,
                "exact": true
              },
              {
                "path": "/sales/ss/generalsettleapproval",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__sales__ss__GeneralSettleApproval' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/sales/ss/GeneralSettleApproval'), loading: LoadingComponent}),
                "locale": false,
                "passAuthVerify": true,
                "exact": true
              },
              {
                "path": "/sales/ss/SoldToSettleApproval",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__sales__ss__SoldToSettleApproval' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/sales/ss/SoldToSettleApproval'), loading: LoadingComponent}),
                "locale": false,
                "passAuthVerify": true,
                "exact": true
              }
            ]
          },
          {
            "path": "/sales/sp",
            "routes": [
              {
                "path": "/sales/sp/payment",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__sales__sp__Payment' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/sales/sp/Payment'), loading: LoadingComponent}),
                "locale": false,
                "exact": true
              },
              {
                "path": "/sales/sp/mypayment",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__sales__sp__MyPayment' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/sales/sp/MyPayment'), loading: LoadingComponent}),
                "locale": false,
                "name": "我的活动付款单",
                "exact": true
              },
              {
                "path": "/sales/sp/generalpaymentapproval",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__sales__sp__GeneralPaymentApproval' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/sales/sp/GeneralPaymentApproval'), loading: LoadingComponent}),
                "locale": false,
                "passAuthVerify": true,
                "exact": true
              },
              {
                "path": "/sales/sp/soldtopaymentapproval",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__sales__sp__SoldToPaymentApproval' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/sales/sp/SoldToPaymentApproval'), loading: LoadingComponent}),
                "locale": false,
                "passAuthVerify": true,
                "exact": true
              },
              {
                "path": "/sales/sp/freegoodspaymentapproval",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__sales__sp__FreeGoodsPaymentApproval' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/sales/sp/FreeGoodsPaymentApproval'), loading: LoadingComponent}),
                "locale": false,
                "passAuthVerify": true,
                "exact": true
              }
            ]
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/mdm",
        "layout": true,
        "locale": false,
        "dynamic": true,
        "routes": [
          {
            "path": "/mdm/soldtogroup",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__mdm__SoldToGroup' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/mdm/SoldToGroup'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/mdm/soldto",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__mdm__SoldTo' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/mdm/SoldTo'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/mdm/soldToMaster",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__mdm__SoldToMaster' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/mdm/SoldToMaster'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/mdm/shipto",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__mdm__ShipTo' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/mdm/ShipTo'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/mdm/shop",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__mdm__Shop' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/mdm/Shop'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/mdm/bo",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__mdm__BO' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/mdm/BO'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/mdm/ec",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__mdm__EC' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/mdm/EC'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/mdm/product",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__mdm__Product' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/mdm/Product'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/mdm/supplier",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__mdm__Supplier' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/mdm/Supplier'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/mdm/bohq",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__mdm__BOHQ' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/mdm/BOHQ'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/mdm/custsys",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__mdm__CustSys' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/mdm/CustSys'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/mdm/soldtologinactionlog",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__mdm__SoldToLoginActionLog' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/mdm/SoldToLoginActionLog'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/mdm/activitytype",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__mdm__ActivityType' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/mdm/ActivityType'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/mdm/adjusttype",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__mdm__AdjustType' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/mdm/AdjustType'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/mdm/bg",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__mdm__Bg' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/mdm/Bg'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/mdm/relationBg",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__mdm__RelationBg' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/mdm/RelationBg'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/mdm/productPriceCust",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__mdm__ProductPriceCust' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/mdm/ProductPriceCust'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/mdm/department",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__mdm__Department' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/mdm/Department'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/mdm/communication_purpose",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__mdm__CommunicationPurpose' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/mdm/CommunicationPurpose'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/mdm/spending_category",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__mdm__SpendingCategory' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/mdm/SpendingCategory'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/mdm/touch_point",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__mdm__TouchPoint' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/mdm/TouchPoint'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/mdm/subChannel",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__mdm__SubChannel' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/mdm/SubChannel'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/mdm/staffAndCust",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__mdm__StaffAndCust' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/mdm/StaffAndCust'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/mdm/anpDeptAndStaff",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__mdm__AnpDeptAndStaff' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/mdm/AnpDeptAndStaff'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/mdm/soldtoandec",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__mdm__SoldToAndEc' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/mdm/SoldToAndEc'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/financial",
        "layout": true,
        "locale": false,
        "dynamic": true,
        "routes": [
          {
            "path": "/financial/vattype",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__financial__VatType' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/financial/VatType'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/financial/accrual",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__financial__Accrual' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/financial/Accrual'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/financial/periodconfig",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__financial__PeriodConfig' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/financial/PeriodConfig'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/financial/invoices",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__financial__Invoices' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/financial/Invoices'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/financial/myinvoices",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__financial__MyInvoices' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/financial/MyInvoices'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/financial/nontpm",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__financial__NonTPM' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/financial/NonTPM'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/financial/salestarget",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__financial__SalesTarget' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/financial/SalesTarget'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/financial/pool",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__financial__Pool' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/financial/Pool'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          }
        ]
      },
      {
        "path": "/basic",
        "layout": true,
        "locale": false,
        "dynamic": true,
        "routes": [
          {
            "path": "/basic/announcement",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__basic__Announcement' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/basic/Announcement'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/basic/remindtype",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__basic__RemindType' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/basic/RemindType'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/basic/agentapprover",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__basic__AgentApprover' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/basic/AgentApprover'), loading: LoadingComponent}),
            "locale": false,
            "name": "代理审批设置",
            "passAuthVerify": true,
            "exact": true
          },
          {
            "path": "/basic/agentbusiness",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__basic__AgentBusiness' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/basic/AgentBusiness'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/basic/superagentapprover",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__basic__SuperAgentApprover' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/basic/SuperAgentApprover'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/basic/rejectreason",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__basic__RejectReason' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/basic/RejectReason'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/basic/errorconfiguration",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__basic__ErrorConfiguration' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/basic/ErrorConfiguration'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/basic/helpcenter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__basic__HelpCenter' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/basic/HelpCenter'), loading: LoadingComponent}),
            "locale": false,
            "name": "帮助中心",
            "passAuthVerify": true,
            "exact": true
          },
          {
            "path": "/basic/personalsettings",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__basic__PersonalSettings' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/basic/PersonalSettings'), loading: LoadingComponent}),
            "locale": false,
            "name": "个人设置",
            "passAuthVerify": true,
            "exact": true
          },
          {
            "path": "/basic/favoritemenus",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__basic__FavoriteMenus' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/basic/FavoriteMenus'), loading: LoadingComponent}),
            "locale": false,
            "name": "常用菜单",
            "passAuthVerify": true,
            "exact": true
          },
          {
            "path": "/basic/formtype",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__basic__FormType' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/basic/FormType'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/basic/pagetype",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__basic__PageType' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/basic/PageType'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/basic/dept",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__basic__Dept' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/basic/Dept'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/basic/staff",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__basic__Staff' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/basic/Staff'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/basic/systemrole",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__basic__SystemRole' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/basic/SystemRole'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/basic/workflowrole",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__basic__WorkflowRole' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/basic/WorkflowRole'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/basic/optionLog",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__basic__OptionLog' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/basic/OptionLog'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/basic/loginactionlog",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__basic__LoginActionLog' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/basic/LoginActionLog'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/basic/dataoptions",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__basic__DataOptions' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/basic/DataOptions'), loading: LoadingComponent}),
            "locale": false,
            "name": "数据选项",
            "passAuthVerify": true,
            "exact": true
          },
          {
            "path": "/basic/resources",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__basic__Resources' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/basic/Resources'), loading: LoadingComponent}),
            "locale": false,
            "name": "静态资源配置",
            "passAuthVerify": true,
            "exact": true
          },
          {
            "path": "/basic/revenueTypes",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__basic__RevenueTypes' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/basic/RevenueTypes'), loading: LoadingComponent}),
            "locale": false,
            "name": "核算类型",
            "passAuthVerify": true,
            "exact": true
          },
          {
            "path": "/basic/service",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__basic__Service' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/basic/Service'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/basic/servicelog",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__basic__ServiceLog' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/basic/ServiceLog'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/basic/parameter",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__basic__Parameter' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/basic/Parameter'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/basic/soldtouser",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__basic__SoldToUser' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/basic/SoldToUser'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/contract",
        "layout": true,
        "locale": false,
        "dynamic": true,
        "routes": [
          {
            "path": "/contract/salescontract",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__contract__SalesContract' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/contract/SalesContract'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/contract/salescontractindirect/",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__contract__SalesContractindirect' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/contract/SalesContractindirect'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/contract/salestargetcontract",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__contract__SalesTargetContract' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/contract/SalesTargetContract'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/contract/salestargetcontractindirect",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__contract__SalesTargetContractIndirect' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/contract/SalesTargetContractIndirect'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/contract/SalesContractApply",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__contract__SalesContractApply' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/contract/SalesContractApply'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/contract/salescontractapproval",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__contract__SalesContractApproval' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/contract/SalesContractApproval'), loading: LoadingComponent}),
            "locale": false,
            "passAuthVerify": true,
            "exact": true
          },
          {
            "path": "/contract/SalesContract/salescontracthistory",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__contract__SalesContract__salescontracthistory' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/contract/SalesContract/salescontracthistory'), loading: LoadingComponent}),
            "locale": false,
            "passAuthVerify": true,
            "exact": true
          },
          {
            "path": "/contract/myrebateinfo",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__contract__MyRebateinfo' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/contract/MyRebateinfo'), loading: LoadingComponent}),
            "locale": false,
            "name": "我的合同返利核销",
            "exact": true
          },
          {
            "path": "/contract/myrebateinfopayment",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__contract__MyRebateinfoPayment' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/contract/MyRebateinfoPayment'), loading: LoadingComponent}),
            "locale": false,
            "name": "合同返利付款",
            "exact": true
          },
          {
            "path": "/contract/rebatepaymentapproval",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__contract__RebatePaymentApproval' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/contract/RebatePaymentApproval'), loading: LoadingComponent}),
            "locale": false,
            "passAuthVerify": true,
            "exact": true
          },
          {
            "path": "/contract/rebateinfo",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__contract__RebateInfo' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/contract/RebateInfo'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/contract/on-invoiceRebateinfo",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__contract__OnInvoiceRebateinfo' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/contract/OnInvoiceRebateinfo'), loading: LoadingComponent}),
            "locale": false,
            "name": "on invoice合同返利查询",
            "exact": true
          },
          {
            "path": "/contract/expenseNonTpm",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__contract__ExpenseNonTpm' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/contract/ExpenseNonTpm'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/contract/rebatesettle",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__contract__RebateSettle' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/contract/RebateSettle'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/contract/formsalescontract",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__contract__FormSalesContract' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/contract/FormSalesContract'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/contract/formsalescontractapproval",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__contract__FormSalesContractApproval' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/contract/FormSalesContractApproval'), loading: LoadingComponent}),
            "locale": false,
            "passAuthVerify": true,
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/flow",
        "layout": true,
        "locale": false,
        "dynamic": true,
        "routes": [
          {
            "path": "/flow/index",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__flow__Index' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/flow/Index'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/report",
        "layout": true,
        "locale": false,
        "dynamic": true,
        "routes": [
          {
            "path": "/report/accrual",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__report__Accrual' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/report/Accrual'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/report/activity",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__report__Activity' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/report/Activity'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/report/payment",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__report__Payment' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/report/Payment'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/report/budgetytd",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__report__BudgetYtd' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/report/BudgetYtd'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/report/budgetmtd",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__report__BudgetMtd' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/report/BudgetMtd'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/report/budgetec",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__report__BudgetEc' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/report/BudgetEc'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/report/mutiproject",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__report__MutiProject' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/report/MutiProject'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/report/contract",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__report__Contract' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/report/Contract'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/report/custsys",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__report__CustSys' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/report/CustSys'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/report/bg",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__report__Bg' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/report/Bg'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "passAuthVerify": true,
            "path": "/report/calendar",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__report__Calendar' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/report/Calendar'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "passAuthVerify": true,
            "path": "/report/pivot",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__report__Pivot' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/report/Pivot'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/report/sellout",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__report__Sellout' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/report/Sellout'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/report/sellin",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__report__Sellin' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/report/Sellin'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "passAuthVerify": true,
            "path": "/report/print",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__report__Print' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/report/Print'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/report/budgethistory",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__report__BudgetHistory' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/report/BudgetHistory'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/report/sellpos",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__report__Sellpos' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/report/Sellpos'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/report/budgetiogroup",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__report__BudgetIoGroup' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/report/BudgetIoGroup'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/report/iogroupbudgetfinal",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__report__IoGroupBudgetFinal' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/report/IoGroupBudgetFinal'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/report/budgetio",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__report__BudgetIo' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/report/BudgetIo'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/report/budgetioaccrual",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__report__BudgetIoAccrual' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/report/BudgetIoAccrual'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/report/podetail",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__report__PoDetail' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/report/PoDetail'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/report/grdetail",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__report__GrDetail' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/report/GrDetail'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/report/salesactualbyproductgroup",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__report__SalesActualByProductgroup' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/report/SalesActualByProductgroup'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "path": "/report/contracthistory",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__report__ContractHistory' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/report/ContractHistory'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "passAuthVerify": true,
            "path": "/report/activityhome",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__report__ActivityHome' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/report/ActivityHome'), loading: LoadingComponent}),
            "locale": false,
            "name": "活动明细信息",
            "exact": true
          },
          {
            "passAuthVerify": true,
            "path": "/report/custsyshome",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__report__CustSysHome' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/report/CustSysHome'), loading: LoadingComponent}),
            "locale": false,
            "name": "客户费率统计",
            "exact": true
          },
          {
            "passAuthVerify": true,
            "path": "/report/bghome",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__report__BgHome' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/report/BgHome'), loading: LoadingComponent}),
            "locale": false,
            "name": "预算组费率统计",
            "exact": true
          },
          {
            "path": "/report/contractversion",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__report__ContractVersion' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/report/ContractVersion'), loading: LoadingComponent}),
            "locale": false,
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/admin",
        "icon": "crown",
        "locale": false,
        "routes": [
          {
            "path": "/admin/home",
            "routes": [
              {
                "path": "/admin/home/index",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__home__Index' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/admin/home/Index'), loading: LoadingComponent}),
                "locale": false,
                "name": "首页(Develop)",
                "passAuthVerify": true,
                "exact": true
              }
            ]
          },
          {
            "path": "/admin/config",
            "routes": [
              {
                "path": "/admin/config/index",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__config__Index' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/admin/config/Index'), loading: LoadingComponent}),
                "locale": false,
                "name": "Schema配置",
                "passAuthVerify": true,
                "exact": true
              },
              {
                "path": "/admin/config/newform",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__config__NewForm' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/admin/config/NewForm'), loading: LoadingComponent}),
                "locale": false,
                "name": "新建Schema",
                "passAuthVerify": true,
                "exact": true
              },
              {
                "path": "/admin/config/formsettings",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__config__FormSettings' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/admin/config/FormSettings'), loading: LoadingComponent}),
                "locale": false,
                "name": "Schema详细信息",
                "passAuthVerify": true,
                "exact": true
              },
              {
                "name": "编辑Schema",
                "path": "/admin/config/editform",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__config__EditForm' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/admin/config/EditForm'), loading: LoadingComponent}),
                "locale": false,
                "passAuthVerify": true,
                "exact": true
              },
              {
                "name": "配置的表单",
                "path": "/admin/config/editformschema",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__config__EditFormSchema' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/admin/config/EditFormSchema'), loading: LoadingComponent}),
                "locale": false,
                "passAuthVerify": true,
                "exact": true
              }
            ]
          },
          {
            "path": "/admin/coregenerate",
            "routes": [
              {
                "path": "/admin/coregenerate/index",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__coregenerate__Index' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/admin/coregenerate/Index'), loading: LoadingComponent}),
                "locale": false,
                "name": "代码自动生成",
                "passAuthVerify": true,
                "exact": true
              }
            ]
          },
          {
            "path": "/admin/settings",
            "routes": [
              {
                "path": "/admin/settings/dataconfig",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__settings__DataConfig' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/admin/settings/DataConfig'), loading: LoadingComponent}),
                "locale": false,
                "name": "系统配置",
                "passAuthVerify": true,
                "exact": true
              },
              {
                "path": "/admin/settings/accrual",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__settings__Accrual' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/admin/settings/Accrual'), loading: LoadingComponent}),
                "locale": false,
                "name": "预提模拟",
                "passAuthVerify": true,
                "exact": true
              },
              {
                "path": "/admin/settings/processparamater",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__settings__ProcessParamater' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/admin/settings/ProcessParamater'), loading: LoadingComponent}),
                "locale": false,
                "name": "流程参数查询",
                "passAuthVerify": true,
                "exact": true
              },
              {
                "path": "/admin/settings/emailtest",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__settings__EmailTest' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/admin/settings/EmailTest'), loading: LoadingComponent}),
                "locale": false,
                "name": "邮箱测试",
                "passAuthVerify": true,
                "exact": true
              }
            ]
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/home",
        "layout": true,
        "locale": false,
        "dynamic": true,
        "routes": [
          {
            "path": "/home/index",
            "routes": [
              {
                "passAuthVerify": true,
                "path": "/home/index",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__Index' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/home/Index'), loading: LoadingComponent}),
                "locale": false,
                "exact": true
              }
            ]
          },
          {
            "path": "/home/nextfor",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__NextFor' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/home/NextFor'), loading: LoadingComponent}),
            "locale": false,
            "passAuthVerify": true,
            "exact": true
          }
        ]
      },
      {
        "path": "/index.html",
        "redirect": "/home/index",
        "exact": true
      },
      {
        "path": "/",
        "redirect": "/home/index",
        "exact": true
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/var/jenkins_home/workspace/danone/danone-normal-frontend/src/pages/404'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
